/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "components/atoms";
import { Table } from "components/organisms";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Column } from "../@data";
import { getUsers, deleteUserById, updateUserById } from "../store/action";
import ReactPaginate from "react-paginate";
import debounce from "lodash/debounce";
import { ActionColumn } from "@data/columns";
function UserList() {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState({
    skip: 1,
    take: 25,
  });
  const [loader, setLoader] = useState(false);
  // Variable
  const state = useSelector((state) => state?.User);
  const column = useMemo(
    () => [
      ...Column,
      ...ActionColumn(
        "User",
        (e) => dispatch(deleteUserById(e)),
        (e, data) => dispatch(updateUserById(e, { data })),
        (e) => navigate(`/app/users/add?id=${e}`)
      ),
    ],
    [state]
  );
  // Function
  const addNewUser = () => navigate("/app/users/add");
  const paginate = (e) => dispatch(getUsers({ take: 25, ...e }));
  const handler = debounce((e) => setSearch(e?.target?.value), 500);
  // Effects
  useEffect(() => {
    setData(state?.data);
  }, [state?.data]);
  useEffect(() => {
    setCount(Math.ceil(state?.count / query?.take));
  }, [state?.count]);
  useEffect(() => {
    setLoader(state?.loading);
  }, [state?.loading]);
  useEffect(() => {
    setQuery(state?.query);
  }, [state?.query]);
  useEffect(() => {
    paginate({ search });
  }, [search]);
  return (
    <div className="py-4">
      <div className="flex items-center justify-between">
        <input
          type="text"
          className="w-[300px] border border-gray-300 text-sm text-gray-200 p-3 rounded-md"
          placeholder="Search..."
          onChange={handler}
        />
        <Button
          onClick={addNewUser}
          value="Add New User"
          className="w-42 font-light text-md p-3 rounded-md"
        />
      </div>
      <Table loader={loader} columns={column} data={data} />
      <div className="w-full flex justify-end pt-5">
        <ReactPaginate
          previousLabel={""}
          nextLabel={""}
          pageCount={count || 1}
          activeClassName="bg-primary-original text-white"
          forcePage={query?.skip !== 0 ? query?.skip - 1 : 0}
          onPageChange={(page) => paginate({ skip: page?.selected + 1 })}
          pageClassName={
            "p-0 w-10 flex items-center justify-center h-10 rounded-full font-medium text-xs mx-2"
          }
          containerClassName={"flex items-center"}
        />
      </div>
    </div>
  );
}

export default UserList;
