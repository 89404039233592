import React, { memo } from "react";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import {
  ProductFields,
  ProductSwitches,
  ExtraProductSwitches,
  editor,
} from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteFiles, updateProductById } from "../store/action";
import { SEOColumn } from "@data/seo";
import { useMemo } from "react";
import FileUploaderMulti from "components/molecules/multi-file";
import { MdDelete } from "react-icons/md";
import S3Image from "components/atoms/S3Image";

function AddProduct() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [image, setImage] = useState([]);
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState(null);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [tags, setTags] = useState([]);
  const [fields, setFields] = useState(ProductFields);
  const [thumbnailId, setThumbnailId] = useState(null);

  // Variables
  const watch = methods?.watch("category_id");
  const overview = methods?.watch("isOverview");
  const part_no = methods?.watch("part_no");
  const Editor = useMemo(
    () => [
      ...editor,
      {
        name: "overview",
        visible: overview,
        label: "Overview",
      },
    ],
    [overview]
  );
  const getPartValidation = (part_no) =>
    useFetch.get(`/product/part_no/${part_no}`);
  // Functions
  const handler = async (e) => {
    e = {
      ...e,
      price: parseInt(e?.price) < 0 ? 0 : e?.price,
    };
    if (!image && !data) {
      return toast.warning("Please Select Image");
    }
    setLoader(true);
    const tags = e?.tags?.map((v) => v?.value);
    delete e?.tags;
    try {
      if (id) {
        await dispatch(
          updateProductById(id, {
            data: {
              ...e,
              thumbnailId: thumbnailId ? thumbnailId : data?.thumbnailId,
            },
            files: image,
            tags,
          })
        );
      } else {
        await useFetch.post("product", { data: { ...e, thumbnailId }, tags, file: image });
        toast(`Product Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  const initValue = (data) => {
    const { setValue } = methods;
    return [
      ...fields,
      ...ProductSwitches,
      ...SEOColumn,
      ...ExtraProductSwitches,
      ...Editor,
    ]?.forEach((v) => {
      return v?.name === "tags"
        ? setValue(
          "tags",
          data?.ProductTag?.map((v) => ({
            value: v?.tag?.id,
            label: v?.tag?.name,
          }))
        )
        : setValue(v?.name, data[v?.name]);
    });
  };
  const fetchingItem = (name, cb, key) => {
    const { setValue } = methods;
    return useFetch.get(`${name}?take=10000`).then((res) => {
      let payload = [];
      const value = res?.data?.data;
      if (value?.data) {
        payload = value?.data;
      } else {
        payload = value;
      }
      cb(
        payload?.map((v) => {
          return {
            label: v?.name,
            value: v?.id,
          };
        })
      );
      if (data) {
        if (key === "tags") {
          return setValue(
            "tags",
            data?.ProductTag?.map((v) => ({
              value: v?.tag?.id,
              label: v?.tag?.name,
            }))
          );
        }
        return setValue(key, data[key]);
      }
    });
  };
  const pushImage = (e) => {
    const arr = image;
    arr.push(e);
    setImage(arr);
  };
  const removeImg = (id) => {
    setImage((arr) => arr.filter((v) => v !== id));
  };
  const deleteServerImage = (id) => {
    return new Promise((resolve) => resolve(dispatch(deleteFiles(id)))).then(
      () => fetch()
    );
  };
  const fetch = () => {
    useFetch.get(`product/${id}`).then((res) => {
      const { data } = res?.data;
      if (data) {
        return setData(data);
      }
    });
  };
  // Effect
  useEffect(() => {
    const id = searchParams.get("id");

    if (id) {
      setId(id);
    }
    setMounted(true);
    return () => setMounted(false);
  }, []);
  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [id]);
  useEffect(() => {
    if (mounted) {
      if (data) {
        return initValue(data);
      }
    }
  }, [mounted, data]);
  useEffect(() => {
    if (category?.length > 0) {
      const field = ProductFields;
      if (id)
        field[1].disabled = true
      else
        field[1].disabled = false
      setFields([
        ...field,
        {
          name: "category_id",
          placeholder: "Select Category",
          label: "Category",
          id: "category_id",
          type: "text",
          select: true,
          options: category,
          rules: {
            required: "Select Category Please",
          },
        },
        {
          name: "brand_id",
          placeholder: "Select Brand",
          label: "Brand",
          id: "brand_id",
          type: "text",
          select: true,
          options: brand,
          rules: {
            required: "Select Brand Please",
          },
        },
        {
          name: "subCategory_id",
          placeholder: "Enter Sub Category",
          label: "Sub Category",
          id: "subCategory_id",
          type: "text",
          select: true,
          options: subCategory,
          rules: {
            required: "Enter Sub Category Please",
          },
        },
        {
          name: "tags",
          placeholder: "Enter Tags",
          label: "Tags",
          id: "tags",
          type: "text",
          select: true,
          options: tags,
          rules: {
            required: "Enter Tags Please",
          },
          multi: true,
        },
      ]);
    }
  }, [category, subCategory, brand, tags, id]);
  useEffect(() => {
    if (watch)
      fetchingItem(
        `sub-category/byCategoryId/${watch}`,
        setSubCategory,
        "subCategory_id"
      );
  }, [watch, data]);
  useEffect(() => {
    fetchingItem("category", setCategory, "category_id");
    fetchingItem("brand", setBrand, "brand_id");
    fetchingItem("tag?take=10000", setTags, "tags");
  }, [data]);
  useEffect(() => {
    if (!id) {
      if (part_no !== "") {
        getPartValidation(
          part_no?.split("#")?.join("%23")?.split("/")?.join("%2F")
        ).then((res) => {
          if (res?.data?.data) {
            methods?.setError("part_no", {
              type: "manual",
              message: "Part No Already Exists!",
            });
          } else {
            methods?.clearErrors("part_no");
          }
        });
      }
    } else {
      methods?.clearErrors("part_no");
    }
  }, [part_no])
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full ">
        <div className="w-full space-y-6 h-[77vh] overflow-auto p-2">
          <FormGenerator
            title="SEO Info"
            inputs={SEOColumn}
            switches={ProductSwitches}
          />
          <FormGenerator
            title="Basic Info"
            inputs={fields}
            switches={ExtraProductSwitches}
            editor={Editor}
          />
          <FileUploaderMulti
            image={image}
            removeImg={removeImg}
            pushImage={pushImage}
            setThumbnailId={setThumbnailId}
            thumbnailId={thumbnailId}
          />
          <div className="w-full p-5 grid grid-cols-4 gap-10 bg-gray-100 shadow-lg rounded-md">
            {data
              ? data?.ProductFiles?.map((v, k) => {
                return (
                  <div className="relative w-full flex items-center bg-white justify-center">
                    <S3Image src={v?.file?.name} />
                    <div className="absolute flex items-center justify-center opacity-0 hover:opacity-100 transition-all cursor-pointer z-50 w-full h-full top-0 left-0 rounded-md bg-black bg-opacity-50">
                      <div
                        className="p-3 bg-white rounded-full"
                        onClick={e => {
                          e?.preventDefault();
                          e?.stopPropagation()
                          return deleteServerImage(v?.file?.id)
                        }
                        }
                      >
                        <MdDelete
                          className="text-red-500 text-2xl"
                          size={30}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </div>
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(AddProduct);
