import React from "react";
import { memo } from "react";
import { Outlet } from "react-router";
import { withReducer } from "store/withReducer";
import { RBAC } from "utilities/RBAC";
import Reducer from "./store/reducer";

function Tag() {
  return <Outlet />;
}

export default RBAC(memo(withReducer("Tag", Reducer)(Tag)), "Tag");
