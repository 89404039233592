export const BlogFields = [
  {
    name: "name",
    placeholder: "Enter Tag Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Tag Name Please",
    },
  },
];
export const BlogSwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 300,
  },
  {
    Header: "Meta Title",
    id: "meta_title",
    accessor: "meta_title",
    sortable: true,
  },
  {
    Header: "Meta Description",
    id: "meta_description",
    accessor: "meta_description",
    sortable: true,
  },
];
export const ExtraBlogSwitches = [];
export const editor = [
  {
    name: "description",
    placeholder: "Enter Description",
    label: "Description",
    id: "description",
    type: "text",
    visible: true,
    className: "col-span-2",
    rules: {
      required: "Enter Description Please",
    },
  },
];
