import { Fragment, useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { ProtectedRoute } from "router/protected";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menu } from "components/molecules";

export default function Example({ open, setOpen }) {
  const [query, setQuery] = useState("");
  const [pages, setPages] = useState(ProtectedRoute);
  useEffect(() => {
    if (query !== "") {
      const filter = ProtectedRoute[0]?.children?.filter((e) =>
        e?.name?.includes(query)
      );
      setPages(filter);
    } else {
      setPages(ProtectedRoute);
    }
  }, [query]);
  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="w-full h-screen fixed top-0 left-0 bg-black bg-opacity-20 backdrop-blur-sm rounded drop-shadow-sm z-10 flex items-center justify-center"></div>
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox onChange={(item) => (window.location = item.url)}>
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                <div className="border-t border-gray-100 p-4 text-center text-sm">
                  <>
                    <h2 className="w-full text-left py-1 text-md font-semibold text-gray-500 mb-4">
                      Pages
                    </h2>
                    <div onClick={() => setOpen(false)}>
                      {pages?.map((v, k) => {
                        return (
                          <Menu
                            {...v}
                            key={k}
                            className="w-full flex items-center space-x-2 hover:bg-opacity-10 rounded-md hover:bg-black mb-2"
                            labelClass="text-base text-gray-700 font-medium"
                            iconClass="text-white"
                            iconMainClass="w-10 h-10 rounded-md flex items-center justify-center bg-primary-original"
                          />
                        );
                      })}
                    </div>
                  </>
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
// function Menu(props) {
//   const [permission, setPermission] = useState([]);
//   const State = useSelector((state) => state);
//   useEffect(() => {
//     setPermission(State?.user?.roleBased);
//   }, [State]);
//   return permission?.find((v) => v?.name === props?.access) ||
//     props?.access === "*" ? (
//     <>
//       {!props?.isNotAllowed && (
//         <Link to={`/${props?.parent}/${props?.path}`}>
//           <div className="w-full flex items-center space-x-2 hover:bg-opacity-10 rounded-md hover:bg-black mb-2">
//             <div className="w-10 h-10 rounded-md flex items-center justify-center bg-primary-original">
//               <props.icon className="text-xl text-white" aria-hidden="true" />
//             </div>
//             <span className="tracking-wider text-base text-gray-700 font-medium">
//               {props?.name}
//             </span>
//           </div>
//         </Link>
//       )}
//       {props?.children?.map((v, k) => {
//         return <Menu parent={props?.name} {...v} />;
//       })}
//     </>
//   ) : null;
// }
