import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][USERS]";
export const GET_USERS = "GET USERS [APP][USERS]";
export const GET_ROLES = "GET ROLES [APP][USERS]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getUsers = (param) => {
  return (dispatch, getState) => {
    const {
      User: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("user", {
        params,
      })
      .then((res) => {
        const {
          data: { count, data },
        } = res?.data;

        return batch(() => {
          dispatch({
            type: GET_USERS,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deleteUserById = (id) => {
  return (dispatch, getState) => {
    return useFetch
      .delete(`user/${id}`)
      .then((response) => {
        return dispatch(getUsers({}));
      })
      .then(() => {
        return toast("User Deleted!");
      })
      .catch((e) => {
        return toast.error(`Can't Delete User | ${e?.response?.message}`);
      });
  };
};
export const updateUserById = (id, data) => {
  return (dispatch) => {
    return useFetch
      .patch(`user/${id}`, { data })
      .then((response) => {
        return dispatch(getUsers({}));
      })
      .then(() => {
        return toast("User Updated!");
      })
      .catch((e) => {
        return toast.error(`Can't Update User | ${e?.response?.message}`);
      });
  };
};
export const getPrivilages = () => {
  return (dispatch) => {
    return useFetch.get("privilages", {}).then((res) => {
      const { data } = res?.data;
      return batch(() => {
        dispatch({
          type: GET_ROLES,
          payload: data,
        });
      });
    });
  };
};
