import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { memo } from "react";
import { useParams } from "react-router";
import { useFetch } from "utilities/usefetch";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function Invoice() {
  // Hooks
  const [data, setData] = useState(null);
  // Variables
  const { id } = useParams();
  // Functions
  // Effects
  useEffect(() => {
    useFetch.get(`qoute/${id}`).then((response) => {
      setData(response.data?.data);
    });
  }, []);
  return (
    <div className="w-full flex items-center justify-center">
      <div className="w-full bg-white rounded-lg border border-gray-100 overflow-hidden">
        <div className="grid grid-cols-2 gap-10 p-10">
          <div className="col-span-2 items-center justify-center flex">
            <img
              className="w-[300px]"
              src="https://www.switchtechsupply.com/_next/image?url=%2Flogo%2Flogo-color.webp&w=3840&q=75"
              alt=""
            />
          </div>
          <div>
            <p className="font-light text-gray-700">Switch Tech Supply</p>
            <p className="font-light text-gray-700">Cheyenne, WY 82001</p>
          </div>
          <div className="flex justify-end items-end flex-col">
            <div className="flex items-center">
              <p className="font-light text-gray-700">Qoute No</p>
              <p className="ml-3 text-gray-700">#{data?.qouteNumber}</p>
            </div>
            <div className="items-center flex">
              <p className="font-light text-gray-700">Date Issue</p>
              <p className="ml-3 text-gray-700">
                {dayjs(data?.createdAt)?.format("hh:mm A | DD MMM, YYYY")}
              </p>
            </div>
          </div>
        </div>
        <hr className="w-full bg-primary-original h-0.5 bg-opacity-50 border-none" />
        <div className="grid grid-cols-2 gap-10 p-10">
          <div>
            <p className="text-gray-700">
              <b className="mr-2">Name</b>{" "}
              {`${data?.firstName} ${data?.lastName}`}
            </p>
            <p className="text-gray-700">
              <b className="mr-2">Company</b> {data?.Organization}
            </p>
            <p className="text-gray-700">
              <b className="mr-2">Country</b> {data?.Country}
            </p>
          </div>
          <div className="flex items-end flex-col" lg="4">
            <p className="text-gray-700">
              <b className="mr-2">Phone:</b> {data?.phone}
            </p>
            <p className="text-gray-700">
              <b className="mr-2">Email</b> {data?.email}
            </p>
          </div>
        </div>
        <table
          className="min-w-full border-separate"
          style={{ borderSpacing: 0 }}
        >
          <thead className="bg-gray-50">
            <tr>
              <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                Product
              </th>
              <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                Quantity
              </th>
              <th className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">
                Condition
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.QouteProduct?.map((v, k) => {
              return (
                <tr key={k}>
                  <td
                    className={classNames(
                      k !== data.length - 1 ? "border-b border-gray-200" : "",
                      "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    <p className="card-text font-weight-bold mb-25">
                      {v?.Product?.name}
                    </p>
                    <p className="card-text text-nowrap">
                      {v?.Product?.part_no}
                    </p>
                  </td>
                  <td
                    className={classNames(
                      k !== data.length - 1 ? "border-b border-gray-200" : "",
                      "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    <span className="font-weight-bold">{v?.Quantity}</span>
                  </td>
                  <td
                    className={classNames(
                      k !== data.length - 1 ? "border-b border-gray-200" : "",
                      "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                    )}
                  >
                    <span className="font-weight-bold">{v?.Condition}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default memo(Invoice);
