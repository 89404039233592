import { memo, useState } from "react";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import S3Image from "components/atoms/S3Image";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
const FileUploaderBasic = ({ setImage, data, title }) => {
  const [img, setImg] = useState(null);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: true,
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    setImg(preview);
  });
  uppy.on("file-added", (file) => {
    setImage(file?.data);
  });
  return (
    <div className={`w-full flex-col shadow-sm rounded-xl py-3 px-4`}>
      <h1 className="text-black text-opacity-50 text-xl pb-3">
        {title ? title : "Images"}
      </h1>
      <DragDrop uppy={uppy} />
      {img !== null ? (
        <img className="rounded mt-2" src={img} alt="avatar" />
      ) : (
        <div className="w-full py-4 flex-row items-center justify-start space-x-6 space-y-6">
          <S3Image src={data} />
        </div>
      )}
    </div>
  );
};

export default memo(FileUploaderBasic);
