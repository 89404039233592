export const BrandFields = [
  {
    name: "name",
    placeholder: "Enter Tag Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Tag Name Please",
    },
  },
];
export const BrandSwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 400,
  },
];
export const ExtraBrandSwitches = [
  {
    name: "isTop",
    label: "Top Brand",
    id: "isTop",
    defaultValue: false,
    rules: {
      required: false,
    },
  },
  {
    name: "isContent",
    label: "More Content",
    id: "isContent",
    defaultValue: false,
    rules: {
      required: false,
    },
  },
];
export const editor = [];
