import dayjs from "dayjs";

export const PermissionFields = [
  {
    name: "name",
    placeholder: "Enter Permission Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Permission Name Please",
    },
  },
];
export const TagSwitches = [

];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 400,
  },

  {
    Header: "Role",
    id: "UserRoles",
    width: 500,
    sortable: true,
    Cell: ({ row }) => {
      return (
        <div className="flex items-center flex-wrap justify-start -ml-2">
          {row?.original?.permission?.map((v, k) => {
            return (
              <h1
                key={k}
                className="bg-primary-200 cursor-pointer select-none hover:bg-opacity-80 transition-all text-2xs px-3 py-1 m-2 rounded-full text-white"
              >
                {v?.name}
              </h1>
            );
          })}
        </div>
      );
    },
  },
  {
    name: "Created Date",
    accessor: "createdOn",
    id: "createdOn",
    sortable: true,
    Cell: ({row}) => dayjs(row?.original?.createdOn).format("ddd DD MMM, YYYY"),
  },
];
