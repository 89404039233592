import AddCategory from "./add-brand";
import CategoryList from "./list";

export const categoryRoutes = [
  {
    path: "",
    Component: <CategoryList />,
    name: "Category",
    access: "Category",
    isNotAllowed: true,
  },
  {
    path: "add",
    Component: <AddCategory />,
    name: "Add Category",
    access: "Category",
    isNotAllowed: true,
  },
];
