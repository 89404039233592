import React, { memo } from "react";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import {
  BrandFields,
  BrandSwitches,
  ExtraBrandSwitches,
  editor,
} from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateBrandById } from "../store/action";
import { SEOColumn } from "@data/seo";
import { useMemo } from "react";
import FileUploader from "components/organisms/file-uploader";
import { formDataGenerator } from "utilities/helper";

function AddBrand() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState(null);
  // Variables
  const watch = methods?.watch("isContent");
  const Editor = useMemo(
    () => [
      ...editor,
      {
        name: "content",
        visible: watch,
        label: "Content",
      },
    ],
    [watch]
  );
  // Functions
  const handler = async (e) => {
    e = {
      ...e,
      file: image,
    };
    if (!image && !data) {
      return toast.warning("Please Select Image");
    }
    e = formDataGenerator(e);
    setLoader(true);

    try {
      if (id) {
        await dispatch(updateBrandById(id, e));
      } else {
        await useFetch.post("brand", e);
        toast(`Brand Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  const initValue = (data) => {
    const { setValue } = methods;
    return [
      ...BrandFields,
      ...BrandSwitches,
      ...SEOColumn,
      ...ExtraBrandSwitches,
      ...Editor,
    ]?.forEach((v) => {
      return setValue(v?.name, data[v?.name]);
    });
  };
  // Effect
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setId(id);
    }
    setMounted(true);
    return () => setMounted(false);
  }, []);
  useEffect(() => {
    if (id) {
      useFetch.get(`brand/${id}`).then((res) => {
        const { data } = res?.data;
        if (data) {
          return setData(data);
        }
      });
    }
  }, [id]);
  useEffect(() => {
    if (mounted) {
      if (data) {
        setTimeout(() => {
          return initValue(data);
        }, 500);
      }
    }
  }, [mounted, data]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full ">
        <div className="w-full space-y-6 h-[77vh] overflow-auto p-2">
          <FormGenerator
            title="SEO Info"
            inputs={SEOColumn}
            switches={BrandSwitches}
          />
          <FormGenerator
            title="Basic Info"
            inputs={BrandFields}
            switches={ExtraBrandSwitches}
            editor={Editor}
          />
          <FileUploader data={data?.files?.name} setImage={setImage} />
        </div>
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(AddBrand);
