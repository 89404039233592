export const SubCategoryFields = [
  {
    name: "name",
    placeholder: "Enter Tag Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Tag Name Please",
    },
  },
  {
    name: "categoryId",
    placeholder: "Enter Category",
    label: "Category",
    id: "categoryId",
    type: "text",
    select: true,
    options: [],
    rules: {
      required: "Enter Category Please",
    },
  },
  {
    name: "description",
    placeholder: "Enter Description",
    label: "Description",
    id: "description",
    type: "text",
    isMultiple: true,
    className: "col-span-2",
    rules: {
      required: "Enter Description Please",
    },
  },
];
export const SubCategorySwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 400,
  },
];
export const ExtraSubCategorySwitches = [
  {
    name: "isContent",
    label: "More Content",
    id: "isContent",
    defaultValue: false,
    rules: {
      required: false,
    },
  },
];
export const editor = [];
