import AddSubCategory from "./add-subcategory";
import SubCategoryList from "./list";

export const SubcategoryRoutes = [
  {
    path: "",
    Component: <SubCategoryList />,
    name: "SubCategory",
    access: "SubCategory",
    isNotAllowed: true,
  },
  {
    path: "add",
    Component: <AddSubCategory />,
    name: "Add SubCategory",
    access: "SubCategory",
    isNotAllowed: true,
  },
];
