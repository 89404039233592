import { gif } from "constant/images";
import React from "react";
import { twMerge } from "tailwind-merge";

function Button({ value, className, ...props }) {
  return (
    <button
      {...props}
      disabled={props?.disabled || props?.loader}
      className={twMerge(
        `w-full bg-primary-original items-center justify-center flex p-4 rounded-xl text-white font-semibold ${className}`
      )}
    >
      {props?.loader ? (
        <img src={gif.loader} alt="loader" className="w-8" />
      ) : (
        value
      )}
    </button>
  );
}

export default Button;
