import store from "store";
import { MdDelete, MdEdit, MdCheckCircle, MdCancel } from "react-icons/md";
export const ActionColumn = (
  name,
  deleteAction,
  updateAction,
  navigate,
  notStatus = false
) => {
  const state = store.getState();
  if (state?.Auth?.auth) {
    const {
      user: { roleBased },
    } = state?.Auth;

    const permission = roleBased?.find((v) => v?.name === name);
    if (permission) {
      let array = [
        {
          Header: "Status",
          id: "Status",
          width: 100,
          Cell: ({ row: { original } }) => {
            return (
              <button
                disabled={!permission?.update}
                onClick={updateAction.bind(this, original?.id, {
                  isActive: !original?.isActive,
                })}
              >
                {original?.isActive ? (
                  <MdCheckCircle className="text-green-400 text-2xl" />
                ) : (
                  <MdCancel className="text-red-500 text-2xl" />
                )}
              </button>
            );
          },
        },
        {
          Header: "Action",
          id: "Action",
          width: 300,
          Cell: ({ row: { original } }) => {
            return (
              <div className="w-full space-x-3">
                <button
                  onClick={navigate?.bind(this, original?.id)}
                  disabled={!permission?.update}
                >
                  <MdEdit className="text-primary-original text-2xl" />
                </button>
                <button
                  onClick={deleteAction?.bind(this, original?.id)}
                  disabled={!permission?.delete}
                >
                  <MdDelete className="text-red-500 text-2xl" />
                </button>
              </div>
            );
          },
        },
      ];
      if (notStatus === true) {
        array = array.slice(1, array.length);
      }
      return array;
    }
  }
  return [
    {
      Header: "Unauth",
      id: "auth",
    },
  ];
};
