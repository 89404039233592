import dayjs from "dayjs";
export const RolesFields = [
  {
    name: "name",
    placeholder: "Enter Role Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Role Name Please",
    },
  },
];
export const RolesSwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 300,
  },
  {
    Header: "Assign To",
    width: 500,

    Cell: ({ row: { original } }) => (
      <div className="flex flex-row flex-wrap items-center space-x-3">
        {original?.RolePermission?.map((v, k) => {
          return (
            <h1
              key={k}
              className="bg-primary-200 cursor-pointer select-none hover:bg-opacity-80 transition-all text-2xs px-3 py-1 rounded-full text-white"
            >
              {v?.permission?.name}
            </h1>
          );
        })}
      </div>
    ),
  },
  {
    Header: "Created Date",
    width: 400,
    selector: "createdOn",
    id: "createdOn",
    sortable: true,
    Cell: ({ row: { original } }) => (
      <h1 className="text-gray-400">
        {dayjs(original?.createdOn).format("hh:mm A | dddd DD MMM, YYYY")}
      </h1>
    ),
  },
];

export const ExtraRolesSwitches = [];
export const editor = [];
