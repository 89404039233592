import AddPermission from "./add-permission";
import PermissionList from "./list";

export const PermissionRoutes = [
  {
    path: "add",
    Component: <AddPermission />,
    name: "Add Permission",
    access: "Permission",
    isNotAllowed: true,
  },
  {
    path: "",
    Component: <PermissionList />,
    name: "Permission",
    access: "Permission",
    isNotAllowed: true,
  },
];
