import { useState } from "react";
import Uppy from "@uppy/core";
import thumbnailGenerator from "@uppy/thumbnail-generator";
import { DragDrop } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import { useFetch } from "utilities/usefetch";
import { FaRemoveFormat, FaCheckCircle } from "react-icons/fa";
import { memo } from "react";
const FileUploaderMulti = ({
  pushImage,
  image,
  removeImg,
  setThumbnailId,
  thumbnailId,
  singleUpload,
  title,
}) => {
  const [previewArr, setPreviewArr] = useState([]);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    allowMultipleUploads: !singleUpload,
  });

  uppy.use(thumbnailGenerator);

  uppy.on("thumbnail:generated", (file, preview) => {
    const form = new FormData();
    form.append("file", file?.data);
    return useFetch.post("files", form).then((res) => {
      const arr = previewArr;
      arr.push(preview);
      setPreviewArr([...arr]);
      return pushImage(res?.data?.data?.id);
    });
  });
  const deleteImage = (index) => {
    setPreviewArr((e) => e?.filter((v, i) => i !== index));
    const val = image?.find((v, i) => i === index);
    removeImg(val);
    return useFetch.delete(`files/${val}`);
  };
  const renderPreview = () => {
    if (previewArr.length) {
      return previewArr.map((src, index) => (
        <div
          style={{
            backgroundImage: `url(${src})`,
            width: "200px",
            height: "200px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            borderRadius: 10,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            marginTop: "10px",
            border: thumbnailId === image[index] ? "5px solid green" : "none",
          }}
          className="delete"
        >
          <FaRemoveFormat onClick={() => deleteImage(index)} color="white" />
          <FaCheckCircle
            onClick={() => setThumbnailId(image[index])}
            color="white"
            className="ml-2"
          />
        </div>
      ));
    } else {
      return null;
    }
  };

  return (
    <div className={`w-full flex-col shadow-sm rounded-xl py-3 px-4`}>
      <h1 className="text-black text-opacity-50 text-xl pb-3">
        {title ? title : "Images"}
      </h1>
      <DragDrop uppy={uppy} />
      <div className="w-full py-4 flex-row items-center justify-start space-x-6 space-y-6">
        <div className="main-preview">{renderPreview()}</div>
      </div>
    </div>
  );
};

export default memo(FileUploaderMulti);
