import React from "react";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import { UserFields, UserSwitches } from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivilages, updateUserById } from "../store/action";
function AddUser() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(null);
  const [fields, setFields] = useState(UserFields);
  // Variables
  const roles = useSelector((app) => app?.User?.roles);
  // Functions
  const handler = async (e) => {
    setLoader(true);
    try {
      if (id) {
        await dispatch(updateUserById(id, e));
      } else {
        await useFetch.post("user", e);
        toast(`User Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  // Effect
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setId(id);
    }
  }, []);
  useEffect(() => {
    if (id) {
      useFetch.get(`user/${id}`).then((res) => {
        const { data } = res?.data;
        if (data) {
          const { setValue } = methods;
          return [...fields, ...UserSwitches]?.forEach((v) => {
            return setValue(
              v?.name,
              v?.name === "role_id"
                ? data["UserRoles"][0]?.role_id?.id
                : data[v?.name]
            );
          });
        }
      });
    }
    dispatch(getPrivilages());
  }, [id]);
  useEffect(() => {
    if (roles) {
      setFields([
        ...UserFields,
        {
          name: "role_id",
          placeholder: "Select Role",
          label: "Roles",
          id: "role_id",
          type: "role_id",
          select: true,
          options: roles?.map((v) => {
            return {
              label: v?.name,
              value: v?.id,
            };
          }),
          rules: {
            required: "Select Role Please",
          },
        },
      ]);
    }
  }, [roles]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full">
        <FormGenerator
          title="Basic Info"
          inputs={fields}
          switches={UserSwitches}
        />
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default AddUser;
