import { assets } from "constant/images";
import React from "react";
import { Outlet } from "react-router";
import { AuthRBAC } from "utilities/RBAC";

function Auth() {
  return (
    <div className="w-full h-[100vh] grid grid-cols-5">
      <div className="col-span-3 2xl:px-20 px-10">
        <Outlet />
      </div>
      <div className="bg-gradient-to-b col-span-2 from-primary-original to-secondary-original p-10 h-full flex items-center justify-center relative">
        <div className="max-w-sm 3xl:max-w-lg mx-auto">
          <h1 className="3xl:text-[80px] 2xl:text-[60px] text-[40px] font-mono text-gray-100 font-medium">
            “Creativity is intelligence having fun”
          </h1>
          <h1 className="font-mono text-left text-white font-medium mt-6">
            - Freedy Coleman
          </h1>
        </div>
        <img
          src={assets?.logo?.mini}
          className="w-20 absolute bottom-7 right-7"
          alt="Logo"
        />
      </div>
    </div>
  );
}

export default AuthRBAC(Auth);
