import React, { memo } from "react";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import {
  RolesFields,
  RolesSwitches,
  ExtraRolesSwitches,
  editor,
} from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateRolesById } from "../store/action";
import { useMemo } from "react";

function AddRoles() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState(null);
  const [Permission, setPermission] = useState([]);
  const [fields, setFields] = useState(RolesFields);

  // Variables
  const Editor = useMemo(() => [...editor], []);
  // Functions
  const handler = async (e) => {
    e = {
      ...e,
      permission: e?.permission?.map((v) => ({
        appPermissionId: v?.value,
      })),
    };
    setLoader(true);
    try {
      if (id) {
        await dispatch(updateRolesById(id, e));
      } else {
        await useFetch.post("roles", e);
        toast(`Roles Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  const initValue = (data) => {
    const { setValue } = methods;
    return [
      ...RolesFields,
      ...RolesSwitches,
      ...ExtraRolesSwitches,
      ...Editor,
    ]?.forEach((v) => {
      return setValue(v?.name, data[v?.name]);
    });
  };
  // Effect
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setId(id);
    }
    setMounted(true);
    return () => setMounted(false);
  }, []);
  useEffect(() => {
    if (id) {
      useFetch.get(`roles/detail/${id}`).then((res) => {
        const { data } = res?.data;
        if (data) {
          return setData(data);
        }
      });
    }
  }, [id]);
  useEffect(() => {
    if (mounted) {
      useFetch.get("permission/app?take=10000").then((res) => {
        const {
          data: { data },
        } = res?.data;
        return setPermission(
          data?.map((v) => {
            return {
              label: v?.name,
              value: v?.id,
            };
          })
        );
      });
      if (data) {
        setTimeout(() => {
          return initValue(data);
        }, 500);
      }
    }
  }, [mounted, data]);
  useEffect(() => {
    if (Permission?.length > 0) {
      setFields([
        ...RolesFields,
        {
          name: "permission",
          placeholder: "Select Roles Permission",
          label: "Roles Permission",
          id: "permission",
          options: Permission,
          type: "text",
          select: true,
          multi: true,
          rules: {
            required: "Select Roles Permission Please",
          },
        },
      ]);
    }
  }, [Permission]);
  useEffect(() => {
    if (Permission?.length > 0) {
      if (data) {
        const { setValue } = methods;
        setValue(
          "permission",
          data?.RolePermission?.map((v) => ({
            value: v?.permission?.id,
            label: v?.permission?.name,
          }))
        );
      }
    }
  }, [data, Permission]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full ">
        <div className="w-full space-y-6 h-[77vh] overflow-auto p-2">
          <FormGenerator
            title="Basic Info"
            inputs={fields}
            switches={ExtraRolesSwitches}
            editor={Editor}
          />
        </div>
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(AddRoles);
