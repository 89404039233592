import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useFormContext, useController } from "react-hook-form";
import { memo } from "react";

const Editors = ({ name }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value, ...field },
  } = useController({ control, name });
  return (
    <>
      <Editor
        ref={field.ref}
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        value={value}
        onEditorChange={onChange}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "preview",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          body_class: "shadow-none border-none font-sans",
          contextmenu: "link image imagetools table spellchecker lists",
        }}
      />
    </>
  );
};
export default memo(Editors);
