import React, { useState } from "react";
// import Table from "components/organisms/table";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "store/actions";
import Router from "./router";
import Search from "components/organisms/search";
import { BrowserRouter } from "react-router-dom";
import Loader from "components/organisms/loader";

function App() {
  // Hooks
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const [loader, setLoader] = useState(true);
  // Variable
  const loading = useSelector((state) => state?.Auth?.settings?.loader);
  // Function
  const detechKey = (e) => {
    const { ctrlKey, key } = e;
    if (ctrlKey && key === "Enter") {
      setSearch(true);
    }
  };
  // Effect
  useEffect(() => {
    setLoader(loading);
  }, [loading]);
  useEffect(() => {
    document.addEventListener("keydown", detechKey, true);
  }, []);
  useEffect(() => {
    dispatch(refreshToken());
  }, []);
  return (
    <BrowserRouter>
      {loader && <Loader />}
      <Search open={search} setOpen={setSearch} />
      <Router />
    </BrowserRouter>
  );
}

export default App;
