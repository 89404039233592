import { applyMiddleware, compose, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import CreateReducer from "./reducer/combine";
const store = createStore(
  CreateReducer(),
  compose(applyMiddleware(thunk, logger))
);
store.asyncReducers = {};
export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(CreateReducer(store.asyncReducers));
  return store;
};
export default store;
