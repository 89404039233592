import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][BLOG][CATEGORY]";
export const GET_BLOG_CATEGORY = "GET BLOG CATEGORY [APP][BLOG][CATEGORY]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getBlogCategory = (param) => {
  return (dispatch, getState) => {
    const {
      BlogCategory: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("blog-category", {
        params,
      })
      .then((res) => {
        const {
          data: { count, data },
        } = res?.data;

        return batch(() => {
          dispatch({
            type: GET_BLOG_CATEGORY,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deleteBlogCategoryById = (id) => {
  return (dispatch, getState) => {
    return useFetch
      .delete(`blog-category/${id}`)
      .then((response) => {
        return dispatch(getBlogCategory({}));
      })
      .then(() => {
        return toast("Blog Category Deleted!");
      })
      .catch((e) => {
        return toast.error(
          `Can't Delete Blog Category | ${e?.response?.message}`
        );
      });
  };
};
export const updateBlogCategoryById = (id, data) => {
  return (dispatch) => {
    return useFetch
      .patch(`blog-category/${id}`, data)
      .then((response) => {
        return dispatch(getBlogCategory({}));
      })
      .then(() => {
        return toast("Blog Category Updated!");
      })
      .catch((e) => {
        return toast.error(
          `Can't Update Blog Category | ${e?.response?.message}`
        );
      });
  };
};
