export const UserFields = [
  {
    name: "fullname",
    placeholder: "Enter User Full Name",
    label: "Full Name",
    id: "fullname",
    type: "text",
    rules: {
      required: "Enter Fullname Please",
    },
  },
  {
    name: "email",
    placeholder: "Enter Email",
    label: "Email",
    id: "email",
    type: "text",
    rules: {
      required: "Enter Email Please",
    },
  },
  {
    name: "password",
    placeholder: "Enter Password",
    label: "Password",
    id: "password",
    type: "password",
    rules: {
      required: false,
    },
  },
];
export const UserSwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Fullname",
    id: "fullname",
    accessor: "fullname",
    sortable: true,
    width: 400,
  },
  {
    Header: "Email",
    accessor: "email",
    id: "email",
    width: 500,
    sortable: true,
    cell: (row) => <a href={row.email}>{row.email}</a>,
  },
  {
    Header: "Role",
    id: "UserRoles",
    width: 500,
    sortable: true,
    Cell: ({ row }) => {
      return row?.original?.UserRoles?.map((v, k) => {
        return (
          <h1
            key={k}
            className="bg-primary-200 cursor-pointer select-none hover:bg-opacity-80 transition-all text-2xs px-3 py-1 rounded-full text-white"
          >
            {v?.role_id?.name}
          </h1>
        );
      });
    },
  },
];
