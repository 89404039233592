import React, { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

function Toggler(props) {
  const { name, defaultValue, label, id, change } = props;
  const { control } = useFormContext();
  return (
    <label
      htmlFor={id}
      class="inline-flex relative items-center cursor-pointer"
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        defaultChecked={defaultValue}
        render={({ field: { onChange, value } }) => (
          <input
            id={id}
            type={"checkbox"}
            class="sr-only peer"
            checked={value}
            onChange={change ? change : onChange}
            defaultChecked={defaultValue}
          />
        )}
      />
      <div class="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:shadow-md after:content-[''] after:absolute after:top-[5px] after:left-[6px] peer-checked:after:left-[10px] after:bg-white after:rounded-full after:h-3.5 after:w-3.5 after:transition-all peer-checked:bg-primary-200"></div>
      <span class="ml-3 text-sm font-mont font-light text-gray-300 capitalize">
        {label}
      </span>
    </label>
  );
}

export default memo(Toggler);
