import axios from "axios";
const BASEV2 = "https://api.switchtechsupply.com/api/v2/"
const useFetch = axios.create({
  // baseURL: "http://localhost:3000/api/v1/",
  baseURL: "https://api-stagging.switchtechsupply.com/api/v1/",
});
useFetch.interceptors.request.use(
  async (config) => {
    config.header = config.headers;
    if (localStorage.getItem("TOKEN")) {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem("TOKEN")
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

useFetch.interceptors.response.use(function (response) {
  if (response.status === 401) {
  }
  return response;
});

const useFetchV2 = axios.create({
  baseURL: BASEV2
});
useFetchV2.interceptors.request.use(
  async (config) => {
    config.header = config.headers;
    if (localStorage.getItem("TOKEN")) {
      config.headers.Authorization = `Bearer ${JSON.parse(
        localStorage.getItem("TOKEN")
      )}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

useFetchV2.interceptors.response.use(function (response) {
  if (response.status === 401) {
  }
  return response;
});

export { useFetch, useFetchV2, BASEV2 };
//
