import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][BRAND]";
export const GET_BRAND = "GET BRAND [APP][BRAND]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getBrand = (param) => {
  return (dispatch, getState) => {
    const {
      Brand: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("brand", {
        params,
      })
      .then((res) => {
        const { count, data } = res?.data;

        return batch(() => {
          dispatch({
            type: GET_BRAND,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deleteBrandById = (id) => {
  return (dispatch, getState) => {
    return useFetch
      .delete(`brand/${id}`)
      .then((response) => {
        return dispatch(getBrand({}));
      })
      .then(() => {
        return toast("Brand Deleted!");
      })
      .catch((e) => {
        return toast.error(`Can't Delete Brand | ${e?.response?.message}`);
      });
  };
};
export const updateBrandById = (id, data) => {
  return (dispatch) => {
    return useFetch
      .patch(`brand/${id}`, data)
      .then((response) => {
        return dispatch(getBrand({}));
      })
      .then(() => {
        return toast("Brand Updated!");
      })
      .catch((e) => {
        return toast.error(`Can't Update Brand | ${e?.response?.message}`);
      });
  };
};
