import dayjs from "dayjs";

export const Column = [
  {
    Header: "Qoute No",
    id: "qouteNumber",
    accessor: "qouteNumber",
    sortable: true,
  },
  {
    Header: "Name",
    id: "firstName",
    accessor: "firstName",
    sortable: true,
  },
  {
    Header: "Email",
    id: "email",
    accessor: "email",
    sortable: true,
    width: 300,
  },
  {
    Header: "Country",
    id: "Country",
    accessor: "Country",
    sortable: true,
    width: 200,
  },
  {
    Header: "Phone",
    id: "phone",
    accessor: "phone",
    sortable: true,
    width: 200,
  },
  {
    Header: "Products",
    id: "products",
    sortable: true,
    width: 150,
    Cell: ({ row: { original } }) => {
      return <span className="w-full flex items-center justify-center">{original?.QouteProduct?.length}</span>;
    },
  },
  {
    Header: "Date/Time",
    id: "datetime",
    sortable: true,
    width: 250,
    Cell: ({ row: { original } }) => {
      return <>{dayjs(original?.createdAt).format("DD MMM, YYYY : hh:mm:ss A")}</>;
    },
  },
];

export const editor = [];
