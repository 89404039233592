import { useFetch } from "./usefetch";

export const formDataGenerator = (item) => {
  let form_data = new FormData();
  for (var key in item) {
    if (item[key] != null) form_data.append(key, item[key]);
  }
  return form_data;
};
export const uploadFile = (e) => {
  const form = new FormData();
  form.append("file", e);
  return useFetch.post("files", form).then((res) => {
    return res?.data?.data?.id;
  });
};
