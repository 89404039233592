import AddBlogCategory from "./add-blog-category";
import BlogCategoryList from "./list";

export const BlogCategoryRoutes = [
  {
    path: "add",
    Component: <AddBlogCategory />,
    name: "Add Blog Categorys",
    access: "BlogCategory",
    isNotAllowed: true,
  },
  {
    path: "",
    Component: <BlogCategoryList />,
    name: "Add Blog Categorys",
    access: "BlogCategory",
    isNotAllowed: true,
  },
];
