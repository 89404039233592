import AddBrand from "./add-brand";
import BrandList from "./list";

export const brandRoutes = [
  {
    path: "",
    Component: <BrandList />,
    name: "Brand",
    access: "Brand",
    isNotAllowed: true,
  },
  {
    path: "add",
    Component: <AddBrand />,
    name: "Add Brand",
    access: "Brand",
    isNotAllowed: true,
  },
];
