import QouteList from "./list";
import QouteInvoice from "./invoice";
export const qouteRoutes = [
  {
    path: "",
    Component: <QouteList />,
    name: "Qoute",
    access: "Qoute",
    isNotAllowed: true,
  },
  {
    path: "invoice/:id",
    Component: <QouteInvoice />,
    name: "Invoice",
    access: "Qoute",
    isNotAllowed: true,
  },
];
