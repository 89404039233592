import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

function Menu(props) {
  const [permission, setPermission] = useState([]);
  const State = useSelector((state) => state?.Auth);
  const { pathname } = useLocation();
  const active = pathname?.split("/")[2] === props?.path;
  useEffect(() => {
    setPermission(State?.user?.roleBased);
  }, [State]);
  return permission?.find((v) => v?.name === props?.access) ||
    props?.access === "*" ? (
    <>
      {!props?.isNotAllowed && (
        <div className="py-1">
          <Link
            className={`w-full ${
              active ? ` ${props.activeClass}` : "text-white"
            }`}
            to={`/${props?.parent}/${props?.path}`}
          >
            <div
              className={twMerge(
                `${
                  active ? `bg-white` : ``
                } px-4 py-3 rounded-md w-full flex items-center space-x-3 ${
                  props.className
                }`
              )}
            >
              <div className={twMerge(`${props.iconMainClass}`)}>
                <props.icon
                  className={twMerge(`text-xl ${props.iconClass}`)}
                  aria-hidden="true"
                />
              </div>
              <span className={twMerge(`tracking-wider ${props.labelClass}`)}>
                {props?.name}
              </span>
            </div>
          </Link>
        </div>
      )}
      {props?.children?.map((v, k) => {
        return <Menu parent={props?.name} {...v} />;
      })}
    </>
  ) : null;
}

export default Menu;
