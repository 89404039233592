import { Navigate } from "react-router";
import AddTag from "./add-tag";
import TagList from "./list";

export const tagRoutes = [
  {
    path: "add",
    Component: <AddTag />,
    name: "Add Tags",
    access: "Tag",
    isNotAllowed: true,
  },
  {
    path: "",
    Component: <TagList />,
    name: "Add Tags",
    access: "Tag",
    isNotAllowed: true,
  },
];
