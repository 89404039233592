import AddBlog from "./add-blog";
import BlogList from "./list";

export const BlogRoutes = [
  {
    path: "",
    Component: <BlogList />,
    name: "Blog",
    access: "Blog",
    isNotAllowed: true,
  },
  {
    path: "add",
    Component: <AddBlog />,
    name: "Add Blog",
    access: "Blog",
    isNotAllowed: true,
  },
];
