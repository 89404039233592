import AddRoles from "./add-roles";
import RolesList from "./list";

export const RolesRoutes = [
  {
    path: "",
    Component: <RolesList />,
    name: "Roles",
    access: "Roles",
    isNotAllowed: true,
  },
  {
    path: "add",
    Component: <AddRoles />,
    name: "Add Role",
    access: "Roles",
    isNotAllowed: true,
  },
];
