import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][PERMISSION]";
export const GET_PERMISSION = "GET TAG [APP][PERMISSION]";
export const GET_DB_TABLES = "GET DB TABLES [APP][PERMISSION]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getDBTables = () => {
  return (dispatch) => {
    return useFetch.get("helper/db").then((res) => {
      return dispatch({
        type: GET_DB_TABLES,
        payload: res?.data?.data,
      });
    });
  };
};
export const getPermissions = (param) => {
  return (dispatch, getState) => {
    const {
      Permission: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("permission/app", {
        params,
      })
      .then((res) => {
        const {
          data: { count, data },
        } = res?.data;

        return batch(() => {
          dispatch({
            type: GET_PERMISSION,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deletePermissionById = (id) => {
  return (dispatch, getState) => {
    return useFetch
      .delete(`tag/${id}`)
      .then((response) => {
        return dispatch(getPermissions({}));
      })
      .then(() => {
        return toast("Permission Deleted!");
      })
      .catch((e) => {
        return toast.error(`Can't Delete Permission | ${e?.response?.message}`);
      });
  };
};
export const updatePermissionById = (id, data) => {
  return (dispatch) => {
    return useFetch
      .patch(`tag/${id}`, data)
      .then((response) => {
        return dispatch(getPermissions({}));
      })
      .then(() => {
        return toast("Permission Updated!");
      })
      .catch((e) => {
        return toast.error(`Can't Update Permission | ${e?.response?.message}`);
      });
  };
};
