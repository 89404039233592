import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][PRODUCT]";
export const GET_PRODUCT = "GET PRODUCT [APP][PRODUCT]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getProduct = (param) => {
  return (dispatch, getState) => {
    const {
      Product: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("product", {
        params,
      })
      .then((res) => {
        const {
          data: { count, data },
        } = res?.data;

        return batch(() => {
          dispatch({
            type: GET_PRODUCT,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deleteProductById = (id) => {
  return (dispatch, getState) => {
    return useFetch
      .delete(`product/${id}`)
      .then((response) => {
        return dispatch(getProduct({}));
      })
      .then(() => {
        return toast("Product Deleted!");
      })
      .catch((e) => {
        return toast.error(`Can't Delete Product | ${e?.response?.message}`);
      });
  };
};
export const updateProductById = (id, data) => {
  return (dispatch) => {
    return useFetch
      .patch(`product/${id}`, data)
      .then((response) => {
        return dispatch(getProduct({}));
      })
      .then(() => {
        return toast("Product Updated!");
      })
      .catch((e) => {
        return toast.error(`Can't Update Product | ${e?.response?.message}`);
      });
  };
};
export const deleteFiles = (id) => {
  return (dispatch, getState) => {
    return useFetch.delete(`files/product/delete/${id}`).then(() => {
      return toast("Image Deleted Successfully!");
    });
  };
};
