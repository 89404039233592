import React, { memo } from "react";
import { Outlet } from "react-router";
import { withReducer } from "store/withReducer";
import { RBAC } from "utilities/RBAC";
import Reducer from "./store/reducer";

function BlogCategory() {
  return <Outlet />;
}

export default RBAC(
  memo(withReducer("BlogCategory", Reducer)(BlogCategory)),
  "BlogCategory"
);
