import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][BLOG]";
export const GET_BLOG = "GET BLOG [APP][BLOG]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getBlog = (param) => {
  return (dispatch, getState) => {
    const {
      Blog: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("blogs", {
        params,
      })
      .then((res) => {
        const {
          data: { count, data },
        } = res?.data;

        return batch(() => {
          dispatch({
            type: GET_BLOG,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deleteBlogById = (id) => {
  return (dispatch, getState) => {
    return useFetch
      .delete(`blogs/${id}`)
      .then((response) => {
        return dispatch(getBlog({}));
      })
      .then(() => {
        return toast("Blog Deleted!");
      })
      .catch((e) => {
        return toast.error(`Can't Delete Blog | ${e?.response?.message}`);
      });
  };
};
export const updateBlogById = (id, data) => {
  return (dispatch) => {
    return useFetch
      .patch(`blogs/${id}`, data)
      .then((response) => {
        return dispatch(getBlog({}));
      })
      .then(() => {
        return toast("Blog Updated!");
      })
      .catch((e) => {
        return toast.error(`Can't Update Blog | ${e?.response?.message}`);
      });
  };
};
