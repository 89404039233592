import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch, useFetchV2 } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][QOUTES]";
export const GET_QOUTES = "GET QOUTES [APP][QOUTES]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getQoutes = (param) => {
  return (dispatch, getState) => {
    const {
      Qoute: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("qoute", {
        params,
      })
      .then((res) => {
        const { count, data } = res?.data?.data;

        return batch(() => {
          dispatch({
            type: GET_QOUTES,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deleteQouteById = (id) => {
  return (dispatch, getState) => {
    return useFetchV2
      .delete(`qoute/${id}`)
      .then((response) => {
        return dispatch(getQoutes({}));
      })
      .then(() => {
        return toast("Qoute Deleted!");
      })
      .catch((e) => {
        return toast.error(`Can't Delete Qoute | ${e?.response?.message}`);
      });
  };
};
