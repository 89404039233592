export const BlogCategoryFields = [
  {
    name: "name",
    placeholder: "Enter Tag Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Tag Name Please",
    },
  },
];
export const BlogCategorySwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 400,
  },
];
