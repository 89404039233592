/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "components/atoms";
import { Table } from "components/organisms";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Column } from "../@data";
import {
  getProduct,
  deleteProductById,
  updateProductById,
} from "../store/action";
import ReactPaginate from "react-paginate";
import debounce from "lodash/debounce";
import { ActionColumn } from "@data/columns";
import { memo } from "react";
import { useFetch, useFetchV2, BASEV2 } from "utilities/usefetch";
import Select from "components/atoms/select";
import { useForm, FormProvider } from "react-hook-form"
import axios from "axios";

function ProductList() {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useForm({
    mode: "onChange"
  })
  const [progress, setProgress] = useState(0)
  const [expoting, setExpoting] = useState(false)
  const [file, setFile] = useState(null)
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState([])
  const [filterLoader, setFilterLoader] = useState(false);
  const [subCategory, setSubCategory] = useState([]);
  const [brand, setBrand] = useState([])
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState({
    skip: 1,
    take: 25,
  });
  const [loader, setLoader] = useState(false);
  // Variable
  const category_id = form.watch("category_id")
  const brand_id = form.watch("brand_id")
  const subCategory_id = form.watch("subCategory_id")
  const state = useSelector((state) => state?.Product);
  const column = useMemo(
    () => [
      ...Column,
      ...ActionColumn(
        "Product",
        (e) => dispatch(deleteProductById(e)),
        (e, data) => dispatch(updateProductById(e, { data })),
        (e) => navigate(`/app/products/add?id=${e}`)
      ),
    ],
    [state]
  );
  // Function
  const addNewProduct = () => navigate("/app/products/add");
  const paginate = (e) => dispatch(getProduct({ take: 25, ...e }));
  const handler = debounce((e) => setSearch(e?.target?.value), 500);
  const payloadGenerator = () => ({ search, category: category_id ? [category_id] : [], brand: brand_id ? [brand_id] : [], subCategory: subCategory_id ? [subCategory_id] : [] })
  const exportProduct = () => {
    setExpoting(true)
    const payload = payloadGenerator();
    return useFetchV2.get("/export/product", {
      params: {
        ...payload,
        take: count * 25
      }
    }).then((res) => {
      setExpoting(false)
      return window.open(`${BASEV2}excel/${res?.data?.result}`)
    })

  }
  const uploading = (file) => {
    setFile(file)
    const config = {
      onUploadProgress: progressEvent => {
        let prog = (progressEvent.loaded / progressEvent.total) * 100;
        setProgress(prog)
      }
    }
    let formData = new FormData();
    formData.append("file", file);
    axios.post(`${BASEV2}import/product`, formData, config).then(res => {
      if (res.data.status === 200) {
        console.log("done: ", res.data.message);
      }
    }).catch(err => {
      console.log("error: ", err.message);
    })
  }
  // Effects
  useEffect(() => {
    setData(state?.data);
  }, [state?.data]);
  useEffect(() => {
    setCount(Math.ceil(state?.count / query?.take));
  }, [state?.count]);
  useEffect(() => {
    setLoader(state?.loading);
  }, [state?.loading]);
  useEffect(() => {
    setQuery(state?.query);
  }, [state?.query]);
  useEffect(() => {
    paginate(payloadGenerator());
  }, [search, category_id, subCategory_id, brand_id]);
  useEffect(() => {
    setFilterLoader(true)
    Promise.all([useFetch.get("category?take=250000").then((res) => res?.data?.data?.data), useFetch.get("brand?take=250000").then((res) => res?.data?.data)]).then((response) => {
      const [category, brand] = response
      setCategory(category.map((v) => ({ value: v?.id, label: v?.name })))
      setBrand(brand.map((v) => ({ value: v?.id, label: v?.name })))
      return setFilterLoader(false)
    })
  }, []);
  useEffect(() => {
    if (category_id)
      useFetch.get(`sub-category/byCategoryId/${category_id}?take=250000`).then((response) => {
        return setSubCategory(response?.data?.data?.map((v) => ({
          value: v?.id,
          label: v?.name
        })))
      })
  }, [category_id])
  useEffect(() => {
    (async () => {
      if (progress === 100) {
        await new Promise(r => setTimeout(r, 2000))
        setFile(null);
        paginate(payloadGenerator());
      }
    })()
  }, [progress])
  return (
    <FormProvider {...form}>
      <div className="py-4">
        <div className="flex items-center justify-between">

          <input
            type="text"
            className="w-[300px] border border-gray-300 text-sm text-gray-200 p-3 rounded-md"
            placeholder="Search..."
            onChange={handler}
          />

          <Button
            onClick={addNewProduct}
            value="Add New Product"
            className="w-46 font-light text-md p-3 rounded-md"
          />
        </div>
        <div className="grid grid-cols-4 items-center justify-center gap-10 pt-8">
          <Select
            name={"category_id"}
            label={"Category"}
            id="category_id"
            options={category}
            loading={filterLoader}
            placeholder={"Select Category"}
            labelClass="text-xs font-medium"
            inputClass="placeholder-black placeholder-opacity-25 font-light text-xs py-1 border-0 border-b"
          />
          <Select
            name={"brand_id"}
            label={"Brand"}
            id="brand_id"
            options={brand}
            loading={filterLoader}
            placeholder={"Select Brand"}
            labelClass="text-xs font-medium"
            inputClass="placeholder-black placeholder-opacity-25 font-light text-xs py-1 border-0 border-b"
          />
          <Select
            isDisabled={subCategory?.length === 0}
            name={"subCategory_id"}
            label={"Sub Category"}
            id="subCategory_id"
            options={subCategory}
            loading={filterLoader}
            placeholder={"Select Brand"}
            labelClass="text-xs font-medium"
            inputClass="placeholder-black placeholder-opacity-25 font-light text-xs py-1 border-0 border-b"
          />
          <div className="pb-2 grid grid-cols-2 gap-5">
            <Button
              onClick={exportProduct}
              loader={expoting}
              value="Export Product"
              className=" font-light text-md p-3 rounded-md"
            />
            <input type="file" className="hidden" id="upload" onChange={e => uploading(e?.target?.files[0])} />
            <label htmlFor="upload"
              className="text-primary-original bg-transparent relative border overflow-hidden border-primary-original font-light text-md p-3 rounded-md text-center"
            >
              {file ? <div className="h-full bg-green-700 text-white flex items-center justify-center text-xl font-medium absolute top-0 left-0" style={{ width: `${progress}%` }}>
                {progress} %
              </div> : "Import Product"}

            </label>
          </div>
        </div>
        <Table loader={loader} columns={column} data={data} />
        <div className="w-full flex justify-end pt-5">
          <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            pageCount={count || 1}
            activeClassName="bg-primary-original text-white"
            forcePage={query?.skip !== 0 ? query?.skip - 1 : 0}
            onPageChange={(page) => paginate({ skip: page?.selected + 1 })}
            pageClassName={
              "p-0 w-10 flex items-center justify-center h-10 rounded-full font-medium text-xs mx-2"
            }
            containerClassName={"flex items-center"}
          />
        </div>
      </div>
    </FormProvider>
  );
}

export default memo(ProductList);
