import React from "react";
import PropTypes from "prop-types";
import { Input } from "components/atoms";
import Toggler from "components/atoms/toggler";
import Editor from "components/molecules/editor";
import TextArea from "components/atoms/text-area";
import Select from "components/atoms/select";

function FormGenerator(props) {
  return (
    <>
      <div
        className={`w-full grid grid-cols-2 gap-x-10 gap-y-4 items-center shadow-sm rounded-xl py-5 px-6`}
      >
        <h1 className="text-black text-opacity-50 text-xl pb-2">
          {props.title}
        </h1>

        <div className="flex items-center justify-end space-x-4">
          {props?.switches?.map((v, k) => {
            return (
              <Toggler
                key={k}
                name={v?.name}
                label={v?.label}
                type={v?.type}
                rules={v?.rules}
                id={v?.id}
                placeholder={v?.placeholder}
                defaultValue={v?.defaultValue}
              />
            );
          })}
        </div>
        {props?.inputs?.map((v, k) => {
          return v?.select ? (
            <Select
              multi={v?.multi}
              key={k}
              name={v?.name}
              label={v?.label}
              type={v?.type}
              rules={v?.rules}
              id={v?.id}
              options={v?.options}
              loading={v?.loading}
              placeholder={v?.placeholder}
              labelClass="text-xs font-medium"
              inputClass="placeholder-black placeholder-opacity-25 font-light text-xs py-1 border-0 border-b"
            />
          ) : v?.isMultiple ? (
            <TextArea
              key={k}
              name={v?.name}
              label={v?.label}
              type={v?.type}
              rules={v?.rules}
              id={v?.id}
              placeholder={v?.placeholder}
              labelClass="mb-0 text-xs font-medium"
              inputClass="placeholder-black placeholder-opacity-25 font-light text-xs py-4"
            />
          ) : (
            <Input
              key={k}
              name={v?.name}
              label={v?.label}
              disabled={v?.disabled}
              type={v?.type}
              rules={v?.rules}
              id={v?.id}
              placeholder={v?.placeholder}
              labelClass="mb-0 text-xs font-medium"
              inputClass="placeholder-black placeholder-opacity-25 font-light text-xs py-4"
            />
          );
        })}
        {props?.children}
      </div>
      {props?.editor?.map((v, k) => {
        return (
          v?.visible && (
            <div className={`w-full flex-col shadow-sm rounded-xl `}>
              <h1 className="text-black text-opacity-50 text-xl px-4 pt-3">
                {v?.label}
              </h1>
              <Editor key={k} name={v?.name} />
            </div>
          )
        );
      })}
    </>
  );
}
FormGenerator.propTypes = {
  title: PropTypes.string,
  switches: PropTypes.arrayOf([
    {
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.bool,
    },
  ]),
  inputs: PropTypes.array,
};
export default FormGenerator;
