import { GET_PERMISSION, TOGGLE_LOADER, GET_DB_TABLES } from "../action";

const init = {
  data: [],
  loading: false,
  query: {
    skip: 1,
    take: 25,
  },
  count: 0,
  db: [],
};
const Reducer = (state = init, action) => {
  switch (action?.type) {
    case GET_DB_TABLES: {
      return {
        ...state,
        db: action?.payload,
      };
    }
    case GET_PERMISSION: {
      return {
        ...state,
        ...action?.payload,
      };
    }
    case TOGGLE_LOADER: {
      return {
        ...state,
        loading: !state?.loading,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default Reducer;
