import React from "react";
import Toggler from "components/atoms/toggler";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import { PermissionFields } from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePermissionById, getDBTables } from "../store/action";
function AddPermission() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(null);
  const [fields, setFields] = useState([]);
  const [permission, setPermission] = useState([]);
  // Variables
  const db = useSelector((App) => App?.Permission?.db);
  // Functions
  const handler = async (e) => {
    setLoader(true);
    try {
      if (id) {
        await dispatch(updatePermissionById(id, e));
      } else {
        const permission = [];
        const payload = {
          name: e?.name,
          permission,
        };
        Object.keys(e).forEach((v) => {
          if (v !== "name") {
            permission.push({ ...e[v], name: v });
          }
        });
        await useFetch.post("permission/app", payload);
        toast(`Permission Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  const updatePermission = (id, val) => {
    return useFetch.put(`permission/app/${id}`, val).then(async () => {
      getId()
      return toast.success("Permission Updated!");
    });
  };
  const getId = () => {
    useFetch.get(`permission/app/${id}`).then((res) => {
      const { data } = res?.data;
      if (data) {
        const { setValue } = methods;
        const permission = data?.permission?.map((v) => {
          return {
            name: v?.name,
            id: v?.id,
            options: ["read", "write", "update", "delete"].map((val) => {
              return {
                name: `${v?.name}.${val}`,
                label: val,
                type: "toggle",
                defaultValue: v[val],
                rules: {
                  required: false,
                },
              };
            }),
            toggle: true,
          };
        });

        setPermission([...permission]);
        permission.forEach((v) => {
          return v?.options?.forEach((val) => {
            setValue(val?.name, val?.defaultValue);
          });
        });
        return [...PermissionFields]?.forEach((v) => {
          return setValue(v?.name, data[v?.name]);
        });
      }
    });
  };
  // Effect
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setId(id);
    }
  }, []);
  useEffect(() => {
    if (!id) {
      const permission = db?.map((v) => {
        return {
          name: v,
          options: ["read", "write", "update", "delete"].map((val) => {
            return {
              name: `${v}.${val}`,
              label: val,
              id: `${v}.${val}`,
              type: "toggle",
              defaultValue: false,
              rules: {
                required: false,
              },
            };
          }),
          toggle: true,
        };
      });
      setFields([...permission]);
    }
  }, [db]);
  useEffect(() => {
    if (id) {
      getId();
    } else {
      dispatch(getDBTables());
    }
  }, [id]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full">
        <FormGenerator
          className="grid-cols-5"
          title="Basic Info"
          inputs={PermissionFields}
          heading="col-span-5"
        >
          {loader ? (
            <div>Loading</div>
          ) : (
            (id ? permission : fields)?.map((v, k) => {
              return (
                <div className="col-span-2 grid grid-cols-5" key={k}>
                  <label
                    className={`text-sm text-black text-opacity-50 mb-1 capitalize`}
                  >
                    {v?.name}
                  </label>
                  {v?.options?.map((val, i) => {
                    return (
                      <Toggler
                        key={i}
                        name={val?.name}
                        label={val?.label}
                        type={val?.type}
                        rules={val?.rules}
                        id={val?.id}
                        placeholder={val?.placeholder}
                        defaultValue={val?.defaultValue}
                        change={() =>
                          updatePermission(v.id, {
                            [val?.name.split(".")[1]]: !val?.defaultValue,
                          })
                        }
                      />
                    );
                  })}
                </div>
              );
            })
          )}
        </FormGenerator>
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default AddPermission;
