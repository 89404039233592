import React from "react";
import { assets } from "constant/images";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { handleLoginSuccess } from "store/actions";
import { Button, Input } from "components/atoms";
import { useState } from "react";
import { toast } from "react-toastify";

function Login() {
  // Hooks
  const methods = useForm({
    mode: "onSubmit",
  });
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const handler = (e) => {
    setLoader(true);
    return dispatch(handleLoginSuccess(e))
      .then((e) => {
        console.log("EEE", e)
        setLoader(false);
        toast("Login Successfully!");
      })
      .catch((e) => {
        setLoader(false);
        methods?.setError("email", {
          message: "Email Maybe Wrong!",
        });
        methods?.setError("password", {
          message: "Password Maybe Wrong!",
        });
      });
  };
  return (
    <div className="flex flex-col max-w-3xl mx-auto items-center h-full justify-center">
      <div className="w-full">
        <img
          src={assets?.logo?.login}
          className="w-[300px] mb-10"
          alt="login-logo"
        />
      </div>
      <FormProvider {...methods}>
        <form
          className="w-full space-y-6"
          onSubmit={methods.handleSubmit(handler)}
        >
          <Input
            name="email"
            type="email"
            placeholder="Enter Your Email..."
            label="Email"
            id="email"
            rules={{ required: "Email Should be not empty!" }}
            icon={
              <img
                className="w-5 mr-4"
                src={assets?.icon?.mail}
                alt="mail-icon"
              />
            }
          />
          <Input
            name="password"
            type="password"
            placeholder="Enter Your Password..."
            label="Password"
            id="password"
            rules={{ required: "Password Should be not empty!" }}
            icon={
              <img
                className="w-5 mr-4"
                src={assets?.icon?.password}
                alt="mail-icon"
              />
            }
          />
          <Button loader={loader} value="LOGIN" />
        </form>
      </FormProvider>
    </div>
  );
}

export default Login;
