import React, { memo } from "react";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import {
  SubCategoryFields,
  SubCategorySwitches,
  ExtraSubCategorySwitches,
  editor,
} from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateSubCategoryById } from "../store/action";
import { SEOColumn } from "@data/seo";
import { useMemo } from "react";
import FileUploader from "components/organisms/file-uploader";
import { formDataGenerator } from "utilities/helper";

function AddSubCategory() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState(null);
  const [category, setCategory] = useState([]);
  const [fields, setFields] = useState(SubCategoryFields);
  const [categoryLoading, setCategoryLoading] = useState(false);
  // Variables
  const watch = methods?.watch("isContent");
  const Editor = useMemo(
    () => [
      ...editor,
      {
        name: "content",
        visible: watch,
        label: "Content",
      },
    ],
    [watch]
  );
  // Functions
  const handler = async (e) => {
    e = {
      ...e,
      file: image,
    };
    if (!image && !data) {
      return toast.warning("Please Select Image");
    }
    e = formDataGenerator(e);
    setLoader(true);

    try {
      if (id) {
        await dispatch(updateSubCategoryById(id, e));
      } else {
        await useFetch.post("sub-category", e);
        toast(`SubCategory Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  const initValue = (data) => {
    const { setValue } = methods;
    return [
      ...fields,
      ...SubCategorySwitches,
      ...SEOColumn,
      ...ExtraSubCategorySwitches,
      ...Editor,
    ]?.forEach((v) => {
      return setValue(v?.name, data[v?.name]);
    });
  };
  // Effect
  useEffect(() => {
    useFetch.get("category?take=10000").then((res) => {
      const {
        data: { data },
      } = res?.data;
      return setCategory(
        data?.map((v) => {
          return {
            label: v?.name,
            value: v?.id,
          };
        })
      );
    });
    const id = searchParams.get("id");
    if (id) {
      setId(id);
    }
    setMounted(true);
    return () => setMounted(false);
  }, []);
  useEffect(() => {
    if (id) {
      setCategoryLoading(true);
      useFetch.get(`sub-category/${id}`).then((res) => {
        const { data } = res?.data;
        setCategoryLoading(false);
        if (data) {
          return setData(data);
        }
      });
    }
  }, [id]);
  useEffect(() => {
    if (mounted) {
      if (data) {
        return initValue(data);
      }
    }
  }, [mounted, data]);
  useEffect(() => {
    if (category?.length > 0) {
      setFields(
        SubCategoryFields?.map((v) =>
          v?.select ? { ...v, options: category, loading: categoryLoading } : v
        )
      );
    }
  }, [category, categoryLoading]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full ">
        <div className="w-full space-y-6 h-[77vh] overflow-auto p-2">
          <FormGenerator
            title="SEO Info"
            inputs={SEOColumn}
            switches={SubCategorySwitches}
          />
          <FormGenerator
            title="Basic Info"
            inputs={fields}
            switches={ExtraSubCategorySwitches}
            editor={Editor}
          />
          <FileUploader data={data?.file?.name} setImage={setImage} />
        </div>
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(AddSubCategory);
