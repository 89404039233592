import React from "react";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import { BlogCategoryFields, BlogCategorySwitches } from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateBlogCategoryById } from "../store/action";
import { memo } from "react";
function AddBlogCategory() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  // Variables
  // Functions
  const handler = async (e) => {
    setLoader(true);
    try {
      if (id) {
        await dispatch(updateBlogCategoryById(id, e));
      } else {
        await useFetch.post("blog-category", e);
        toast(`Blog Category Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  // Effect
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setId(id);
    }
  }, []);
  useEffect(() => {
    if (id) {
      useFetch.get(`blog-category/${id}`).then((res) => {
        const { data } = res?.data;

        console.log("Data", data);
        if (data) {
          const { setValue } = methods;
          return [...BlogCategoryFields, ...BlogCategorySwitches]?.forEach(
            (v) => {
              return setValue(v?.name, data[v?.name]);
            }
          );
        }
      });
    }
  }, [id]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full">
        <FormGenerator
          title="Basic Info"
          inputs={BlogCategoryFields}
          switches={BlogCategorySwitches}
        />
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(AddBlogCategory);
