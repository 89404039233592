import React, { memo } from "react";
import { FormGenerator } from "components/organisms";
import { Button } from "components/atoms";
import { BlogFields, BlogSwitches, ExtraBlogSwitches, editor } from "../@data";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useFetch } from "utilities/usefetch";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateBlogById } from "../store/action";
import { SEOColumn } from "@data/seo";
import { useMemo } from "react";
import FileUploader from "components/organisms/file-uploader";
import { formDataGenerator } from "utilities/helper";

function AddBlog() {
  // Hooks
  const methods = useForm();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const [mounted, setMounted] = useState(false);
  const [data, setData] = useState(null);
  const [category, setCategory] = useState([]);
  const [fields, setFields] = useState(BlogFields);

  // Variables
  const Editor = useMemo(() => [...editor], []);
  // Functions
  const handler = async (e) => {
    e = {
      ...e,
      file: image,
    };
    if (!image && !data) {
      return toast.warning("Please Select Image");
    }
    e = formDataGenerator(e);
    setLoader(true);

    try {
      if (id) {
        await dispatch(updateBlogById(id, e));
      } else {
        await useFetch.post("blogs", e);
        toast(`Blog Added Succesfully!`);
      }
      setLoader(false);
      return navigate(-1);
    } catch (e) {
      setLoader(false);
      return toast.error(`Something went wrong! ${e?.response?.data.message}`);
    }
  };
  const initValue = (data) => {
    const { setValue } = methods;
    return [
      ...BlogFields,
      ...BlogSwitches,
      ...SEOColumn,
      ...ExtraBlogSwitches,
      ...Editor,
    ]?.forEach((v) => {
      return setValue(v?.name, data[v?.name]);
    });
  };
  // Effect
  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      setId(id);
    }
    setMounted(true);
    return () => setMounted(false);
  }, []);
  useEffect(() => {
    if (id) {
      useFetch.get(`blogs/${id}`).then((res) => {
        const { data } = res?.data;
        if (data) {
          return setData(data);
        }
      });
    }
  }, [id]);
  useEffect(() => {
    if (mounted) {
      useFetch.get("blog-category?take=10000").then((res) => {
        const {
          data: { data },
        } = res?.data;
        return setCategory(
          data?.map((v) => {
            return {
              label: v?.name,
              value: v?.id,
            };
          })
        );
      });
      if (data) {
        setTimeout(() => {
          return initValue(data);
        }, 500);
      }
    }
  }, [mounted, data]);
  useEffect(() => {
    if (category?.length > 0) {
      setFields([
        ...BlogFields,
        {
          name: "blogCategoryId",
          placeholder: "Enter Blog Category",
          label: "Blog Category",
          id: "blogCategoryId",
          options: category,
          type: "text",
          select: true,
          rules: {
            required: "Enter Blog Category Please",
          },
        },
      ]);
    }
  }, [category]);
  useEffect(() => {
    if (category?.length > 0) {
      if (data) {
        const { setValue } = methods;
        setValue("blogCategoryId", data["BlogOfCategory"][0]?.category?.id);
      }
    }
  }, [data, category]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className="w-full ">
        <div className="w-full space-y-6 h-[77vh] overflow-auto p-2">
          <FormGenerator
            title="SEO Info"
            inputs={SEOColumn}
            switches={BlogSwitches}
          />
          <FormGenerator
            title="Basic Info"
            inputs={fields}
            switches={ExtraBlogSwitches}
            editor={Editor}
          />
          <FileUploader data={data?.Files?.name} setImage={setImage} />
        </div>
        <div className="flex justify-end pt-6">
          <Button
            loader={loader}
            value={id ? "Update" : "Save"}
            className="w-56"
            type="submit"
          />
        </div>
      </form>
    </FormProvider>
  );
}

export default memo(AddBlog);
