/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";

function RBAC(WrappedCompnent, moduleName) {
  const Authentication = (props) => {
    // Hooks
    const [allow, setAllow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [path] = useState(
      location?.pathname?.includes("auth")
        ? "/app/dashboard"
        : location?.pathname
    );
    // State Hooks
    const auth = useSelector((state) => state?.Auth?.auth);
    const permission = useSelector((state) => state?.Auth?.user?.roleBased);
    const [counter, setCounter] = useState(0);
    useEffect(() => {
      const isAllowed = permission?.find((v) => v?.name === moduleName)
        ? true
        : false;
      setAllow(isAllowed);
    }, [permission]);
    useEffect(() => {
      if (counter > 0) {
        if (!auth) {
          navigate("/auth/login");
        } else {
          navigate(path);
        }
      } else {
        setCounter(1);
      }
    }, [auth]);
    return allow ? <WrappedCompnent {...props} /> : <p>404</p>;
  };
  return Authentication;
}
function AuthRBAC(WrappedCompnent) {
  const Authentication = (props) => {
    // Hooks
    // State Hooks
    const auth = useSelector((state) => state?.Auth?.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const [path] = useState(
      location?.pathname?.includes("auth")
        ? "/app/dashboard"
        : location?.pathname
    );

    useEffect(() => {
      if (auth) {
        navigate(path);
      }
    }, [auth]);
    return <WrappedCompnent {...props} />;
  };
  return Authentication;
}
export { RBAC, AuthRBAC };
