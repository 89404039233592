import React from "react";
import { assets } from "constant/images";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Input } from "components/atoms";
function OTP() {
  const methods = useForm({
    mode: "onSubmit",
  });
  const handler = (e) => {};
  return (
    <div className="flex flex-col max-w-2xl mx-auto items-center h-full justify-center">
      <div className="w-full">
        <img
          src={assets?.logo?.otp}
          className="w-[300px] mb-10"
          alt="login-logo"
        />
      </div>
      <FormProvider {...methods}>
        <form
          className="w-full space-y-6"
          onSubmit={methods.handleSubmit(handler)}
        >
          <div className="grid grid-cols-6 gap-12">
            {new Array(6).fill("").map((v) => (
              <Input
                name="otp"
                type="text"
                placeholder="0"
                inputClass="text-5xl leading-none pb-0 placeholder-gray-200 font-medium text-center"
                id="otp"
                rules={{ required: "Otp Should be not empty!" }}
              />
            ))}
          </div>
          <Button value="VERIFY" />
        </form>
      </FormProvider>
    </div>
  );
}

export default OTP;
