import dayjs from "dayjs";

export const ProductFields = [
  {
    name: "name",
    placeholder: "Enter Product Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Product Name Please",
    },
  },
  {
    name: "part_no",
    placeholder: "Enter Product Part No",
    label: "Part No",
    id: "part_no",
    type: "text",
    rules: {
      required: "Enter Product Part No Please",
    },
  },
  {
    name: "Quantity",
    placeholder: "Enter Product Quantity",
    label: "Quantity",
    id: "Quantity",
    type: "number",
    rules: {
      required: "Enter Product Quantity Please",
    },
  },
  {
    name: "price",
    placeholder: "Enter Product Price",
    label: "Price",
    id: "price",
    type: "number",
    rules: {
      required: "Enter Product Price Please",
    },
  },
];
export const ProductSwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 400,
  },
  {
    Header: "Part No",
    id: "part_no",
    accessor: "part_no",
    sortable: true,
  },
  {
    Header: "Category",
    accessor: "category.name",
    id: "category.name",
    width: 200,
    sortable: true,
  },
  {
    Header: "Brands",
    accessor: "brand.name",
    id: "brand.name",
    sortable: true,
  },
  {
    Header: "Quantity",
    accessor: "Quantity",
    id: "Quantity",
    sortable: true,
  },
  {
    Header: "Price",
    accessor: "price",
    id: "price",
    sortable: true,
  },
  {
    Header: "Created By",
    accessor: "User_Product_createdByIdToUser.fullname",
    id: "createdBy.fullname",
    sortable: true,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    id: "createdAt",
    width: 300,
    sortable: true,
    Cell: ({ row: { values } }) => {
      return <>{dayjs(values?.createdAt).format("DD MMM YYYY HH:mm:ss A")}</>
    }
  },
  {
    Header: "Updated At",
    accessor: "updatedAt",
    id: "updatedAt",
    width: 300,
    sortable: true,
    Cell: ({ row: { values } }) => {
      return <>{dayjs(values?.updatedAt).format("DD MMM YYYY HH:mm:ss A")}</>
    }
  },
  {
    Header: "Updated By",
    accessor: "User_Product_updateByIdToUser.fullname",
    id: "updatedBy.fullname",
    sortable: true,
  },
];
export const ExtraProductSwitches = [
  {
    name: "isOverview",
    label: "Overview Content",
    id: "isOverview",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];
export const editor = [
  {
    name: "description",
    label: "Description",
    visible: true,
  },
];
