import { GET_ROLES, TOGGLE_LOADER } from "../action";

const init = {
  data: [],
  loading: false,
  query: {
    skip: 1,
    take: 25,
  },
  count: 0,
};
const Reducer = (state = init, action) => {
  switch (action?.type) {
    case GET_ROLES: {
      return {
        ...state,
        ...action?.payload,
      };
    }
    case TOGGLE_LOADER: {
      return {
        ...state,
        loading: !state?.loading,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};
export default Reducer;
