export const TagsFields = [
  {
    name: "name",
    placeholder: "Enter Tag Name",
    label: "Name",
    id: "name",
    type: "text",
    rules: {
      required: "Enter Tag Name Please",
    },
  },
  {
    name: "link",
    placeholder: "Enter Link Name",
    label: "Link",
    id: "link",
    type: "text",
    rules: {
      required: false,
    },
  },
];
export const TagSwitches = [
  {
    name: "isActive",
    label: "Active",
    id: "isActive",
    defaultValue: true,
    rules: {
      required: false,
    },
  },
];

export const Column = [
  {
    Header: "Name",
    id: "name",
    accessor: "name",
    sortable: true,
    width: 400,
  },
  {
    Header: "Link",
    accessor: "link",
    id: "link",
    width: 500,
    sortable: true,
    cell: (row) => <a href={row.link}>{row.link}</a>,
  },
];
