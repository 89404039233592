import React, { memo } from "react";
import { Outlet } from "react-router";
import { withReducer } from "store/withReducer";
import { RBAC } from "utilities/RBAC";
import Reducer from "./store/reducer";

function Roles() {
  return <Outlet />;
}

export default RBAC(memo(withReducer("Roles", Reducer)(Roles)), "Roles");
