import axios from "axios";
import React, { useEffect, useState } from "react";
export const getImageS3 = (e) => {
  return axios
    .get(`https://api-stagging.switchtechsupply.com/api/v1/files/${e}`)
    .then((res) => {
      return res.data?.url;
    });
};

function S3Image({ className, src, onClick }) {
  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (src && src !== "") {
      getImageS3(src).then((res) => setImage(res));
    }
    setImage("");
  }, [src]);
  useEffect(() => {
    if (image) {
      setShow(true);
    }
  }, [image]);
  return image === "" || !show ? (
    <div class="animate-pulse flex space-x-4">
      <div class={`bg-gray-700  ${className}`}></div>
    </div>
  ) : (
    <div>
      <img
        style={{
          height: "300px",
          objectFit: "contain",
          borderRadius: "10px",
        }}
        src={image}
        alt=""
      />
    </div>
  );
}
S3Image.defaultProps = {
  onClick: () => ({}),
};
export default S3Image;
