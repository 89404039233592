export const SEOColumn = [
  {
    name: "meta_title",
    placeholder: "Enter Meta Title",
    label: "Meta Title",
    id: "meta_title",
    type: "text",
    rules: {
      required: "Enter Meta Title Please",
    },
  },
  {
    name: "meta_description",
    placeholder: "Enter Meta Description",
    label: "Meta Description",
    id: "meta_description",
    type: "text",
    rules: {
      required: "Enter Meta Description Please",
    },
  },
];
