import Home from "pages/app/home";
import Products from "pages/app/products";
import App from "pages/app/template";
import Users from "pages/app/users/index";
import Tag from "pages/app/tags";
import Brand from "pages/app/brand";
import Category from "pages/app/category";
import SubCategory from "pages/app/sub-category";
import BlogCategory from "pages/app/blog-category";
import Blog from "pages/app/blog";
import Roles from "pages/app/roles";
import Qoute from "pages/app/qoute";
import Permission from "pages/app/permission";
import { brandRoutes } from "pages/app/brand/router";
import { categoryRoutes } from "pages/app/category/router";
import { SubcategoryRoutes } from "pages/app/sub-category/router";
import { ProductsRoutes } from "pages/app/products/router";
import { FaBlog, FaBloggerB } from "react-icons/fa";
import { BlogCategoryRoutes } from "pages/app/blog-category/router";
import { BlogRoutes } from "pages/app/blog/router";
import { RolesRoutes } from "pages/app/roles/router";
import { AiFillDashboard, AiFillControl } from "react-icons/ai";
import { RiUserFill, RiGitBranchFill, RiKeyFill } from "react-icons/ri";
import { IoMdPricetag, IoIosFolder, IoIosMail } from "react-icons/io";
import { CgGitBranch } from "react-icons/cg";
import { BsBagFill, BsStack } from "react-icons/bs";
import { tagRoutes } from "pages/app/tags/router";
import { qouteRoutes } from "pages/app/qoute/router";
import { userRoutes } from "pages/app/users/router";
import { PermissionRoutes } from "pages/app/permission/router";

export const ProtectedRoute = [
  {
    path: "/app",
    Component: <App />,
    isNotAllowed: true,
    name: "app",
    access: "*",
    children: [
      {
        path: "dashboard",
        Component: <Home />,
        icon: AiFillDashboard,
        name: "Dashboard",
        access: "User",
      },
      {
        path: "users",
        Component: <Users />,
        icon: RiUserFill,
        name: "Users",
        access: "User",
        children: userRoutes,
      },
      {
        path: "tags",
        Component: <Tag />,
        icon: IoMdPricetag,
        name: "Tags",
        access: "Tag",
        children: tagRoutes,
      },
      {
        path: "brands",
        Component: <Brand />,
        icon: IoIosFolder,
        name: "Brands",
        access: "Brand",
        children: brandRoutes,
      },
      {
        path: "category",
        Component: <Category />,
        icon: CgGitBranch,
        name: "Category",
        access: "Category",
        children: categoryRoutes,
      },
      {
        path: "sub-category",
        Component: <SubCategory />,
        icon: RiGitBranchFill,
        name: "Sub Category",
        access: "SubCategory",
        children: SubcategoryRoutes,
      },
      {
        path: "products",
        Component: <Products />,
        icon: BsBagFill,
        BsStack,
        name: "Products",
        access: "Product",
        children: ProductsRoutes,
      },
      {
        path: "grouping",
        Component: <Products />,
        icon: BsStack,
        name: "Grouping",
        access: "Group",
      },
      {
        path: "qoute",
        Component: <Qoute />,
        icon: IoIosMail,
        name: "Qoute",
        access: "Qoute",
        children: qouteRoutes,
      },
      {
        path: "permission",
        Component: <Permission />,
        icon: AiFillControl,
        name: "Permission",
        access: "Permission",
        children: PermissionRoutes,
      },
      {
        path: "roles",
        Component: <Roles />,
        icon: RiKeyFill,
        name: "Roles",
        access: "Roles",
        children: RolesRoutes,
      },
      {
        path: "blog",
        Component: <Blog />,
        icon: FaBlog,
        name: "Blog",
        access: "Blog",
        children: BlogRoutes,
      },
      {
        path: "blog-category",
        Component: <BlogCategory />,
        icon: FaBloggerB,
        name: "Blog Category",
        access: "BlogCategory",
        children: BlogCategoryRoutes,
      },
    ],
  },
];
