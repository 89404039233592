import { batch } from "react-redux";
import { toast } from "react-toastify";
import { useFetch } from "utilities/usefetch";

// Constant
export const TOGGLE_LOADER = "TOGGLE LOADER [APP][CATEGORY]";
export const GET_CATEGORY = "GET CATEGORY [APP][CATEGORY]";
// Action
export const toggleLoader = () => ({
  type: TOGGLE_LOADER,
});
export const getCategory = (param) => {
  return (dispatch, getState) => {
    const {
      Category: { query },
    } = getState();
    dispatch(toggleLoader());
    const params = {
      ...query,
      ...param,
    };
    return useFetch
      .get("category", {
        params,
      })
      .then((res) => {
        const {
          data: { count, data },
        } = res?.data;

        return batch(() => {
          dispatch({
            type: GET_CATEGORY,
            payload: {
              count,
              data,
              query: params,
            },
          });
          dispatch(toggleLoader());
        });
      });
  };
};
export const deleteCategoryById = (id) => {
  return (dispatch, getState) => {
    return useFetch
      .delete(`category/${id}`)
      .then((response) => {
        return dispatch(getCategory({}));
      })
      .then(() => {
        return toast("Category Deleted!");
      })
      .catch((e) => {
        return toast.error(`Can't Delete Category | ${e?.response?.message}`);
      });
  };
};
export const updateCategoryById = (id, data) => {
  return (dispatch) => {
    return useFetch
      .patch(`category/${id}`, data)
      .then((response) => {
        return dispatch(getCategory({}));
      })
      .then(() => {
        return toast("Category Updated!");
      })
      .catch((e) => {
        return toast.error(`Can't Update Category | ${e?.response?.message}`);
      });
  };
};
