import AddUser from "./add-user";
import UserList from "./list";

export const userRoutes = [
  {
    path: "add",
    Component: <AddUser />,
    name: "Add Users",
    access: "User",
    isNotAllowed: true,
  },
  {
    path: "",
    Component: <UserList />,
    name: "Add Users",
    access: "User",
    isNotAllowed: true,
  },
];
